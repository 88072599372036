// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
// @import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// @import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
// .brand-dark {
//   @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// }
// // Light
// .brand-light {
//   @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
// }

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
    background: none transparent !important;
    margin: 0 !important;
    padding-top: 0.5rem;
    code {
        overflow-y: auto;
        display: block;

        span {
            background: none transparent !important;
        }
    }
}

.json > pre {
    background: none #fff !important;
}

.example-code > .json {
    background: none #fff !important;
}

.symbol.symbol-45 > svg {
    width: 100%;
    max-width: 45px;
    height: 45px;
}

.react-bootstrap-table {
    overflow-x: auto;
}

.react-bootstrap-table {
    th {
        outline: none;

        &.sortable {
            .svg-icon-sort {
                opacity: 0;
            }

            &:hover {
                cursor: pointer;

                .svg-icon-sort {
                    opacity: 1;
                }
            }
        }
    }

    .table.table-head-custom thead tr {
        .sortable-active {
            color: $primary !important;
        }
    }
}

.cursor-default {
    cursor: default !important;
}

// Custom Style
@import "./assets/scss/style.scss";
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// Custom
body {
    font-family: "Kanit", sans-serif;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
}
@media (max-width: 991.98px) {
    .header-mobile.sticky .burger-icon span,
    .header-mobile.sticky .burger-icon span::before,
    .header-mobile.sticky .burger-icon span::after {
        background-color: rgba(0, 0, 0, 0.7);
    }

    .header-mobile.sticky .btn.btn-hover-transparent-white {
        color: #111;
    }

    .header-mobile.sticky
        .btn.btn-hover-transparent-white:hover:not(.btn-text):not(:disabled):not(.disabled)
        .svg-icon
        svg
        g
        [fill],
    .btn.btn-hover-transparent-white:focus:not(.btn-text)
        .svg-icon
        svg
        g
        [fill],
    .btn.btn-hover-transparent-white.focus:not(.btn-text)
        .svg-icon
        svg
        g
        [fill] {
        fill: #000;
    }

    .header-mobile.sticky
        .btn.btn-hover-transparent-white
        .svg-icon
        svg
        g
        [fill] {
        fill: #000;
    }
}

// .fixed-box-shadow {
//     box-shadow: 0px 10px 30px 0px rgb(82 63 105 / 8%) !important;
// }

.font-feels {
    color: #50d587 !important;
}

.btn.btn-default:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-default:focus:not(.btn-text),
.btn.btn-default.focus:not(.btn-text) {
    color: #50d587;
}

.bg-feels {
    background-color: #50d587 !important;
}

.btn-feels {
    color: #fff !important;
    background-color: #50d587 !important;
    border-color: #50d587 !important;
    i {
        color: #fff !important;
    }
}

.btn.btn-feels:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-feels:focus:not(.btn-text),
.btn.btn-feels.focus:not(.btn-text) {
    color: #fff !important;
    background-color: #48ca7e !important;
    border-color: #48ca7e !important;
}

a.text-hover-feels:hover,
.text-hover-feels:hover {
    transition: color 0.15s ease, background-color 0.15s ease,
        border-color 0.15s ease, box-shadow 0.15s ease;
    color: #50d587 !important;
}

// .btn-feels:hover {
//     background-color: #50d587 !important;
//     border-color: #50d587 !important;
// }

.required-text {
    color: rgb(255, 85, 85);
    margin-left: 2px;
}

.w-100-px {
    width: 100px;
}

#product-detail-page {
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        height: 100px;
        width: 100px;
        // outline: black;
        background-size: 100%, 100%;
        // border-radius: 50%;
        // border: 1px solid black;
        background-image: none;
    }

    .carousel-control-next-icon:after {
        content: ">";
        font-size: 55px;
        font-weight: 500;
        color: black;
    }

    .carousel-control-prev-icon:after {
        content: "<";
        font-size: 55px;
        font-weight: 500;
        color: black;
    }

    .carousel-indicators li {
        background-color: black;
    }
}
#loading-section {
    .bounce {
        position: absolute;
        top: 50%;
        left: 0;
        text-align: center;
    }

    .bounce > div {
        width: 22px;
        height: 22px;
        background-color: #fff;
        margin: 5px;
        border-radius: 100%;
        display: inline-block;
        animation: sk-bouncedelay 1.25s infinite ease-in-out both;
    }

    .bounce .bounce1 {
        animation-delay: -0.32s;
    }

    .bounce .bounce2 {
        animation-delay: -0.16s;
    }

    @keyframes sk-bouncedelay {
        0%,
        80%,
        100% {
            transform: scale(0);
        }
        40% {
            transform: scale(1);
        }
    }

    .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9999;
        display: flex;
    }

    .overlay h1 {
        position: absolute;
        display: flex;
        font-weight: 600;
        font-size: 20px;
        font-family: "Kanit", sans-serif;
        color: white;
        top: 50%;
        text-transform: uppercase;
        align-items: center;
        justify-content: center;
        margin-top: 3rem !important;
    }
}

.h-500-px {
    height: 500px;
}

.cliens {
    padding: 12px 0;
    text-align: center;
}

.cliens img {
    max-width: 100%;
    transition: all 0.4s ease-in-out;
    display: inline-block;
    padding: 15px 0;
    filter: grayscale(100);
}

.cliens img:hover {
    filter: none;
    transform: scale(1.1);
}

@media (max-width: 768px) {
    .cliens img {
        max-width: 40%;
    }
}

/* Catalogue styles */
.product-item {
    padding: 12px 12px 16px;
    background: #fff;
    position: relative;
}
.product-list .product-item {
    margin-bottom: 30px;
}
.owl-item.active .product-item {
    margin-right: 10px;
}
.product-item:hover {
    box-shadow: 5px 5px rgba(234, 234, 234, 0.9);
}
.product-item:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
    font-size: 0;
    line-height: 0;
}
*html .product-item {
    zoom: 1;
}
* + html .product-item {
    zoom: 1;
}
.sticker {
    position: absolute;
    top: 0;
    left: 0;
    width: 63px;
    height: 63px;
}
.sticker-sale {
    // background: url(../../pages/img/sale.png) no-repeat;
}
.sticker-new {
    // background: url(../../pages/img/new.png) no-repeat;
    left: auto;
    right: 0;
}
.pi-img-wrapper {
    position: relative;
}
.pi-img-wrapper div {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    width: 100%;
    height: 100%;
    text-align: center;
}
.product-item:hover > .pi-img-wrapper > div {
    display: block;
}
.pi-img-wrapper div .btn-default {
    padding: 3px 10px;
    color: #fff;
    border: 1px #fff solid;
    margin: -13px 5px 0;
    background: transparent;
    position: relative;
    top: 50%;
    font-size: 12px;
}
.product-item .btn:hover {
    background: #e84d1c;
    border-color: #c8c8c8;
}

.product-item h3 {
    // font: 300 14px "Open Sans", sans-serif;
    padding-top: 10px;
    padding-bottom: 4px;
}
.product-item h3 a {
    color: #3e4d5c;
}
.product-item h3 a:hover {
    color: #e02222;
}
.pi-price {
    color: #50d587;
    font-size: 18px;
    // font: 18px "PT Sans Narrow", sans-serif;
    float: left;
    padding-top: 1px;
}
.product-item .add2cart {
    float: right;
    color: #a8aeb3;
    border: 1px #ededed solid;
    padding: 3px 6px;
}
.product-item .add2cart:hover {
    color: #fff !important;
    background: #50d587 !important;
    border-color: #50d587;
}

.shop-index-carousel {
    margin-left: -10px;
}

/* BEGIN product page */
.product-page {
    background: #fff;
    padding: 22px;
    position: relative;
    margin-right: 10px;
}
.product-main-image {
    margin-bottom: 20px;
}
.product-main-image img {
    width: 100%;
}
.product-other-images {
    text-align: left;
}
.product-other-images img {
    width: 58px;
    height: auto;
    margin: 0 12px 12px 0;
}
.product-other-images a:hover img,
.product-other-images a.active img {
    box-shadow: 0 0 0 2px #c7ced5;
}
.product-page h1 {
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 12px;
}
.price-availability-block {
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 12px;
    margin-bottom: 17px;
}
.price-availability-block .price {
    float: left;
    // font-family: "PT Sans Narrow", sans-serif;
}
.price-availability-block .price strong {
    color: #50d587;
    font-size: 35px;
    font-weight: normal;
}
.price-availability-block .price strong span {
    font-size: 25px;
}
.price-availability-block .price em {
    font-style: normal;
    color: #bbb;
    font-size: 17px;
}
.price-availability-block .price em span {
    font-size: 23px;
    text-decoration: line-through;
}
.price-availability-block .availability {
    float: right;
    color: #7b8a99;
}
.price-availability-block .availability strong {
    font-weight: normal;
    color: #3e4d5c;
}
.product-page .nav-tabs > li {
    margin-top: 1px;
}
.product-page-options {
    border-top: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
    padding: 20px 0;
    margin-bottom: 20px;
    width: 100%;
    overflow: hidden;
}
.product-page-options .pull-left {
    margin-right: 40px;
}
.product-page-options .pull-left:last-child {
    margin-right: 0;
}
.product-page-options label {
    font-weight: normal;
    text-transform: uppercase;
    color: #8e9ca8;
    font-family: "PT Sans Narrow", sans-serif;
    float: left;
    margin-right: 10px;
    padding-top: 2px;
}
.product-page-options select.input-sm {
    float: left;
    width: auto;
    height: 26px;
}

.product-page-cart {
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 20px;
    margin-bottom: 18px;
}
.product-quantity,
.product-quantity .input-group {
    width: 70px;
    float: left;
    margin-right: 20px;
    position: relative;
}
table .product-quantity,
table .product-quantity .input-group {
    margin-right: 0;
}
.product-page-cart .btn {
    padding: 7px 20px;
    font-size: 13px;
    height: 38px;
}
.product-quantity input.form-control {
    border: none;
    background: #edeff1 !important;
    font: 300 23px "Open Sans", sans-serif;
    color: #647484;
    height: 38px;
    width: 50px;
    text-align: center;
    padding: 5px;
}
.product-quantity input.form-control:focus {
    border: none;
}

.product-quantity .input-group-btn {
    position: static;
}
.product-quantity .btn {
    text-align: center;
    height: 18px !important;
    width: 18px;
    padding: 0 2px 0 1px !important;
    text-align: center;
    background: #edeff1;
    border-radius: 0 !important;
    font-size: 18px !important;
    line-height: 1 !important;
    color: #616b76;
    margin: 0 !important;
    position: absolute;
    right: 0;
}
.product-quantity .quantity-up {
    top: 0;
}
.product-quantity .quantity-down {
    bottom: 0;
}
.product-quantity .btn i {
    position: relative;
    top: -2px;
    left: 1px;
}

.product-page .review {
    color: #6e7a85;
    font-family: "Open Sans", sans-serif;
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 18px;
    margin-bottom: 20px;
    font-weight: 300;
}

.product-page .rateit {
    margin-right: 27px;
    position: relative;
    top: 2px;
}
.product-page .review a {
    color: #e6400c;
}
.product-page .social-icons li a {
    background-position: 0 -38px;
    opacity: 1;
}

.text-about {
    background-color: #fff;
    text-align: center;
    margin-left: 10vw;
    margin-right: 10vw;
    // margin-top: -300px;
}

.rounded-top-3 {
    border-top-right-radius: 3.25rem !important;
    border-top-left-radius: 3.25rem !important;
}

@media (max-width: 768px) {
    .text-about {
        margin-left: 0;
        margin-right: 0;
    }
}

.grey.lighten-4 {
    background-color: #f5f5f5 !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.cart-box {
    height: 500px;
}

@media (max-width: 480px) {
    .cart-box {
        height: 200px;
    }
}

#cartsummary {
    .item img {
        object-fit: cover;
        border-radius: 5px;
    }

    .item {
        position: relative;
    }

    .number {
        position: absolute;
        font-weight: 800;
        color: #fff;
        background-color: #0033ff;
        padding-left: 7px;
        border-radius: 50%;
        border: 1px solid #fff;
        width: 25px;
        height: 25px;
        top: -5px;
        right: -5px;
    }
}

#product-detail {
    .menu {
        background-color: #313131;
        color: rgb(254, 254, 255);
        text-align: center;
    }

    .button-content {
        background-color: #ffffff;
        color: #313131;
    }

    .sub-menu {
        width: 50%;
        text-align: center;
    }

    .active {
        background: linear-gradient(149deg, #50d587 0%, #00695c 85%) !important;
        background-color: #50d587 !important;
        color: #ffffff;
    }
}

/* shopping cart page */
.goods-data {
    background: #fff;
    padding: 20px;
    // margin-bottom: 20px;
    overflow-x: auto;
    // margin-right: 10px;
}
.goods-data table,
.checkout-page table {
    width: 100%;
    font: 300 13px "Open Sans", sans-serif;
}
.goods-data th,
.checkout-page th {
    font: 16px "PT Sans Narrow", sans-serif;
    text-transform: uppercase;
    border-bottom: solid 1px #ecebeb;
    padding-bottom: 8px;
}
.goods-data td,
.checkout-page td {
    vertical-align: top;
    padding: 20px 20px 20px 0;
    border-bottom: solid 1px #ecebeb;
}
.goods-page-image,
.checkout-image {
    width: 105px;
}
.goods-page-image img,
.checkout-image img {
    width: 75px;
}
.goods-page-price,
.goods-page-total,
.checkout-price,
.checkout-total {
    font-family: "PT Sans Narrow", sans-serif;
}
.goods-page-price strong,
.goods-page-total strong,
.checkout-price strong,
.checkout-total strong {
    color: #e84d1c;
    font-size: 21px;
    font-weight: normal;
}
.goods-page-price strong span,
.goods-page-total strong span,
.checkout-price strong span,
.checkout-total strong span {
    font-size: 17px;
}
.goods-data h3,
.checkout-page .checkout-description h3 {
    font: 300 14px "Open Sans", sans-serif;
    text-transform: inherit;
    padding: 0;
    margin: 0 0 5px;
}
.goods-data p,
.checkout-description p {
    margin-bottom: 2px;
}
.goods-data a,
.checkout-page .checkout-description a {
    color: #e6400c;
}
.goods-page-description em,
.checkout-description em {
    display: block;
    font-style: normal;
}
.goods-page .btn-default {
    float: left;
    border: 1px solid #5ea8dc;
    background: #5ea8dc;
    color: #fff;
}
.goods-page .btn-default:hover {
    border: 1px solid #408cc2;
    background: #408cc2;
}
.goods-page .btn-primary {
    float: right;
    margin-right: 10px;
}

.goods-data .del-goods,
.goods-data .add-goods {
    margin-top: 4px;
}

.shopping-total,
.checkout-total-block {
    width: 230px;
    float: right;
    padding-top: 9px;
    color: #3e4d5c;
}
.checkout-total-block {
    margin-bottom: 20px;
}
.shopping-total ul,
.checkout-total-block ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.shopping-total li,
.checkout-total-block li {
    border-bottom: solid 1px #ecebeb;
    width: 100%;
    overflow: hidden;
    padding: 9px 0;
}
.shopping-total li:last-child,
.checkout-total-block li:last-child {
    border: none;
}
.shopping-total em,
.checkout-total-block em {
    font: 18px "PT Sans Narrow", sans-serif;
    float: left;
    text-transform: uppercase;
    position: relative;
    top: 2px;
}
.shopping-total strong,
.checkout-total-block strong {
    color: #e84d1c;
    font: 21px "PT Sans Narrow", sans-serif;
    font-weight: normal;
    float: right;
}
.shopping-total strong span,
.checkout-total-block strong span {
    font-size: 17px;
}
.shopping-total-price,
.checkout-total-price {
    font-weight: bold;
}
.shopping-total-price strong,
.checkout-total-price strong {
    font-weight: bold;
}

@media (max-width: 480px) {
    .table-wrapper-responsive {
        overflow-x: scroll;
    }
}

.footer-newsletter {
    padding: 50px 0;
    background: #f4faff;
    text-align: center;
    font-size: 16px;
}

.footer-newsletter h3 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 0;
    line-height: 1;
    font-weight: 600;
}

.footer-newsletter input[type="email"] {
    border: 0;
    padding: 10px 12px;
    width: 100%;
    box-shadow: 0 2px 15px rgba(2, 59, 109, 0.12);
    display: block;
}

.footer-newsletter input[type="submit"] {
    font-size: 16px;
    padding: 10px 30px;
    border: 0;
    background: #009bd9;
    color: #fff;
    transition: 0.3s;
    border-radius: 0 3px 3px 0;
    box-shadow: 0 2px 15px rgba(2, 59, 109, 0.12);
}

.footer-newsletter input[type="submit"]:hover {
    background: #00a6e8;
    box-shadow: 0 0 30px rgba(2, 59, 109, 0.2);
}

.footer-newsletter .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: center;
    padding: 15px;
    font-weight: 600;
    margin: 15px 0 0 0;
}

.footer-newsletter .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
    margin: 15px 0 0 0;
}

.xsearch-items {
    padding-left: 0px;
}

.search-item-img {
    float: left;
    position: relative;
}

.search-item-img img,
.search-item-img .img-holder {
    height: 70px;
    width: 70px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
    border: 4px solid white;
}

.search-item-img .img-holder {
    border: 1px solid #e3e3e3;
    line-height: 20px;
    background: #f7f7f7;
    border-color: rgba(207, 215, 223, 0.25);
}

.search-item-img .img-holder i {
    display: inline-block;
    padding: 28px 20px;
    font-size: 28px;
    opacity: 0.5;
}

.search-item-content {
    margin-left: 100px;
    padding-bottom: 35px;
    margin-bottom: 20px;
    /*border-bottom: 1px solid rgb(231, 231, 231);*/
}

.search-item-content.no-excerpt h3 {
    margin-top: 8px;
}

.search-item-content .search-item-meta {
    display: block;
    margin-bottom: 10px;
}

.search-item-meta-down a,
.search-item-meta a {
    font-size: 11px;
    text-transform: uppercase;
}

.xsearch-items a:hover {
    border-bottom-width: 1px;
    border-bottom-style: dotted;
}

.search-item-content .time {
    color: #999;
    font-size: 11px;
    text-transform: uppercase;
}

.search-item-content time,
.search-item-content span {
    color: #999999;
}

.xsearch-items h3.search-item-caption {
    margin-bottom: 2px;
    font-weight: 600;
    font-size: 20px;
}

.xsearch-items .search-item-meta-down {
    margin-top: 5px;
    color: #999;
    font-size: 12px;
}

.xsearch-items .star-vote li {
    padding: 0;
    font-size: 14px;
}

.xsearch-result-count {
    color: #999;
    margin-bottom: 30px;
}

ul.xsearch-items-2 {
    padding-left: 0;
    margin-left: 0;
}

.xsearch-items-2 li {
    list-style: none;
}

.xsearch-info-meta:before,
.xsearch-info-meta:after {
    content: "";
    display: table;
}

.xsearch-info-meta:after {
    clear: both;
}

.xsearch-info-meta {
    padding: 0;
    margin: 0;
    list-style-type: none;
    margin-bottom: 5px;
    font-size: 12px;
    opacity: 0.7;
}

.xsearch-info-meta-item {
    float: left;
    margin-right: 10px;
}

.xsearch-item-title h3,
.xsearch-item-title h4 {
    margin-bottom: 5px;
}

.xsearch-desc {
    margin-bottom: 2px;
}

.search-item-icon {
    padding-right: 3px;
}

nav.xsearch-navbar {
    padding-left: 0;
}

nav.xsearch-navbar.navbar-light .navbar-nav .active > .nav-link {
    border-bottom-width: 2px;
    border-bottom-style: solid;
    padding-bottom: 12px;
}

.xsearch-item .xsearch-item-title strong {
    font-weight: 600;
}

ul.xsearch-items-2 .xsearch-item {
    margin-bottom: 40px;
}

.xsearch-item .xsearch-desc strong {
    color: #111;
}

.search-result-wrap .search-result-item {
    padding-bottom: 25px;
    padding-top: 25px;
    border-bottom: 1px solid rgba(207, 215, 223, 0.25);
}

.search-result-wrap .search-result-item .title h4,
.search-result-wrap .search-result-item .title h3 {
    margin-bottom: 5px;
}

.search-result-item-meta {
    font-size: 14px;
    margin-bottom: 10px;
    margin-left: 25px;
}

.search-result-item-meta li {
    color: #999;
    margin-right: 5px;
}

.search-result-item-meta li i {
    margin-right: 4px;
}

.search-result-item-meta li a {
    /*border-bottom:1px dotted rgba(207,215,223,.25);
    padding-bottom:2px;*/
    color: #1a0dab;
}

.search-result-item-link,
.search-result-item-excerpt,
.search-result-item-meta {
    margin-left: 25px;
}

.search-result-item-excerpt strong {
    color: #444;
    font-weight: 600;
}

.search-result-item-excerpt {
    margin-bottom: 5px;
}

.search-result-item-meta li:first-child {
    margin-left: 0;
    padding-left: 0;
}

// ul {
//     list-style-type: none;
// }

.text-navy {
    color: #1ab394;
}
.cart-product-imitation {
    text-align: center;
    padding-top: 30px;
    height: 80px;
    width: 80px;
    background-color: #f8f8f9;
}
.product-imitation.xl {
    padding: 120px 0;
}
.product-desc {
    padding: 20px;
    position: relative;
}
.ecommerce .tag-list {
    padding: 0;
}
.ecommerce .fa-star {
    color: #d1dade;
}
.ecommerce .fa-star.active {
    color: #f8ac59;
}
.ecommerce .note-editor {
    border: 1px solid #e7eaec;
}
table.shoping-cart-table {
    margin-bottom: 0;
}
table.shoping-cart-table tr td {
    border: none;
    text-align: right;
}
table.shoping-cart-table tr td.desc,
table.shoping-cart-table tr td:first-child {
    text-align: left;
}
table.shoping-cart-table tr td:last-child {
    width: 80px;
}
.ibox {
    clear: both;
    margin-bottom: 25px;
    margin-top: 0;
    padding: 0;
}
.ibox.collapsed .ibox-content {
    display: none;
}
.ibox:after,
.ibox:before {
    display: table;
}
.ibox-title {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #ffffff;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 3px 0 0;
    color: inherit;
    margin-bottom: 0;
    padding: 14px 15px 7px;
    min-height: 48px;
}
.ibox-content {
    background-color: #ffffff;
    color: inherit;
    padding: 15px 20px 20px 20px;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 1px 0;
}
.ibox-footer {
    color: inherit;
    border-top: 1px solid #e7eaec;
    font-size: 90%;
    background: #ffffff;
    padding: 10px 15px;
}
